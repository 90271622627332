import { SampleItem } from "./types";

const BASE_URL = "https://api.dev.quickwayglobal.com";

const toUrl = (relativePath: string) => `${BASE_URL}/${relativePath}`;

const HELLO_URL = toUrl("hello");
const SAMPLES_URL = toUrl("samples");
const SEED_URL = toUrl("samples/seed");

const fetchHello = async (): Promise<string> => {
  const resp = await fetch(HELLO_URL);
  const { message } = await resp.json();
  return message || "";
};

const fetchSeedStatus = async (): Promise<boolean> => {
  const resp = await fetch(SEED_URL);
  const { isSeeded } = await resp.json();
  return isSeeded;
};

const seedDatabase = async (): Promise<void> => {
  const resp = await fetch(SEED_URL, { method: "POST" });
  await resp.json();
};

const fetchItems = async (): Promise<SampleItem[]> => {
  const resp = await fetch(SAMPLES_URL);
  const { items } = await resp.json();
  return items;
};

export const APIClient = {
  fetchHello,
  fetchSeedStatus,
  fetchItems,
  seedDatabase,
};
