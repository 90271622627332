import React, { useEffect, useState } from "react";
import { APIClient } from "./APIClient";

const About: React.FC = () => {
  const [hello, setHello] = useState("");

  useEffect(() => {
    APIClient.fetchHello().then((h) => setHello(h));
  }, []);

  return (
    <div>
      <h1>About</h1>
      {hello !== "" && <p>{hello}</p>}
    </div>
  );
};

export default About;
