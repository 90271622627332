import React, { useEffect, useState } from "react";
import "./App.css";
import { APIClient } from "./APIClient";
import { SampleItem } from "./types";

function App() {
  const [items, setItems] = useState<SampleItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [attemptedInvalidSeed, setAttemptedInvalidSeed] = useState(false);

  const fetchItems = async () => {
    setLoading(true);
    const fetchedItems = await APIClient.fetchItems();
    setItems(fetchedItems.sort((a, b) => a.sampleId - b.sampleId));
    setLoading(false);
  };

  const seedAndReload = async () => {
    const isSeeded = await APIClient.fetchSeedStatus();
    if (isSeeded) {
      setAttemptedInvalidSeed(true);
      return;
    }

    await APIClient.seedDatabase();
    fetchItems();
  };

  // Fetch items on page load
  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (attemptedInvalidSeed) {
      const t = setTimeout(() => {
        setAttemptedInvalidSeed(false);
      }, 3000);

      return () => clearTimeout(t);
    }
  }, [attemptedInvalidSeed]);

  const loadingBody = <p>Loading Items...</p>;
  const itemsBody = (
    <ul>
      {items.map(({ sampleId, sampleMessage }) => (
        <li key={sampleId} style={{ textAlign: "left" }}>
          {sampleMessage}
        </li>
      ))}
    </ul>
  );
  const invalidSeedAttemptBody = <p>Database is already seeded!</p>;

  return (
    <div className="App" style={{ display: "flex" }}>
      <div>{loading ? loadingBody : itemsBody}</div>
      <div>
        <button onClick={seedAndReload}>SeedItems</button>
        {attemptedInvalidSeed && invalidSeedAttemptBody}
      </div>
    </div>
  );
}

export default App;
